import React, { Component } from 'react';
import {
    Collapse,
    Navbar,
    NavbarToggler,
    Nav,
    NavItem,
    NavLink
 } from 'reactstrap';
 import logo from '../assets/logo.png'

class Header extends Component {
    constructor(props1) {
        super(props1);
        this.toggle = this.toggle.bind(this);
        this.state = {
          isOpen: false
        };
      }
      toggle() {
        this.setState({
          isOpen: !this.state.isOpen
        });
    }
    render() {
        let nav;
        if(!localStorage.getItem('token')){
            nav = 
            <Collapse isOpen={this.state.isOpen} navbar className="justify-content-between">
                <Nav className="mr-auto" navbar>
                    <NavItem>
                        <NavLink href="/">Home</NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink href="/contact">Contact Us</NavLink>
                    </NavItem>
                </Nav>
            </Collapse>
        } else {
            nav = 
            <Collapse isOpen={this.state.isOpen} navbar className="justify-content-between">
                <Nav navbar>
                    <NavItem>
                        <NavLink href="/">Home</NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink href="/contact">Contact Us</NavLink>
                    </NavItem>
                </Nav>
                <Nav navbar >
                <img src={logo} alt="Fresh &amp; Direct Logo" height="40" className="d-none d-md-block d-xl-block" />
                </Nav>
                <Nav navbar>
                    <NavItem>
                        <NavLink href="/cart">Cart ({this.props.cartItems})</NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink href="/account">My Account</NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink href="/logout">Logout</NavLink>
                    </NavItem>
                </Nav>
            </Collapse>
        }
        return (
            <div>
        <Navbar className="mb-2" color="success" dark expand="md">
          <NavbarToggler onClick={this.toggle} />
            {nav}
        </Navbar>
      </div>
        );
    }
}

export default Header;