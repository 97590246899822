import React, { Component } from 'react';
import {Redirect} from 'react-router-dom';
import {PostData} from '../services/PostData'
import {
  Col, Form,
  FormGroup, Label, Input,
  Button, FormFeedback
} from 'reactstrap';

class Login extends Component {
    constructor(props) {
        super(props);
          this.state = {
          'email': '',
          'password': '',
          redirect: false,
          validate: {
            emailState: '',
          },
          formError: {
            error: false,
            message: ""
          }
        }
        this.handleChange = this.handleChange.bind(this);
        this.submitForm = this.submitForm.bind(this);
      }
    componentWillMount(e){
      if(localStorage.getItem('token')){
        this.setState({ redirect: true })
      }
    }
    validateEmail(e) {
    const emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const { validate } = this.state
        if (emailRex.test(this.state.email)) {
        validate.emailState = 'has-success'
        } else {
        validate.emailState = 'has-danger'
        e.preventDefault();
        }
        this.setState({ validate })
    }
    handleChange = async (event) => {
        const { target } = event;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const { name } = target;
        await this.setState({
          [ name ]: value,
          formError: {
            error: false
          }
        });
      }
    
    submitForm(e) {
        e.preventDefault();
        PostData('login', this.state).then ((result) => {
          let responseJson = result;
          if(responseJson.token){
            localStorage.setItem('token', responseJson.token)
            this.setState({ redirect: true })
          } else {
            this.setState({formError: responseJson});
          }
        })
    }
    
  render() {
    let formerror;
    if(this.state.formError.error){
      formerror = <div className="text-danger mt-3 text-center">{this.state.formError.message}</div>
    }
    const { email, password } = this.state;
    if(this.state.redirect){
        return (<Redirect to={'/'} />)
    }
    return (
        <Form className="form" onSubmit={ (e) => { this.submitForm(e) } }>
          <Col>
            <FormGroup>
              <Label>Username</Label>
              <Input
                type="email"
                name="email"
                id="exampleEmail"
                placeholder="myemail@email.com"
                value={ email }
                valid={ this.state.validate.emailState === 'has-success' }
                invalid={ this.state.validate.emailState === 'has-danger' }
                autoComplete="email"
                onChange={ (e) => { this.handleChange(e) } }
              />
              <FormFeedback>
                Please input a correct email.
              </FormFeedback>
            </FormGroup>
          </Col>
          <Col>
            <FormGroup>
              <Label for="examplePassword">Password</Label>
              <Input
                type="password"
                name="password"
                id="examplePassword"
                placeholder="********"
                value={ password }
                onChange={ (e) => this.handleChange(e) }
                autoComplete="current-password"
            />
            </FormGroup>
          </Col>
          <Button className="btn btn-lg btn-primary btn-block text-uppercase" type="submit" onClick={(e) => this.validateEmail(e)}>Submit</Button>
            {formerror}
      </Form>
    );
  }
}

export default Login;