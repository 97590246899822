import React, { Component } from 'react';
import Header from './header';
import { Container, Row, Col, Card, CardBody, CardTitle } from 'reactstrap';
import Login from './login'
import Footer from './footer'
import Categories from './categories';
import Products from './products';

class Main extends Component {
  constructor(props) {
    super(props);
    this.state = {
        cartItems: 0,
        searchTerm: '',
        searchCat: ''
    };
    this.searchTerm = this.searchTerm.bind(this);
    this.searchCat = this.searchCat.bind(this);
  }
  addToCart = () => {
    this.setState({
      cartItems: this.state.cartItems + 1
    });
  }
  searchTerm = async (index) => {
    await this.setState({
        searchTerm: index,
        searchCat: ''
    });
  }
  searchCat = async (index) => {
    await this.setState({
        searchCat: index
    });
  }
  render() {
    let content;
    if(!localStorage.getItem('token')){
      content = <div className="main-container">
      <Header/>
        <Container>
          <Row>
            <Col className="col-sm-9 col-md-7 col-lg-5 mx-auto">
              <Card className="card-signin my-5">
                <CardBody>
                  <CardTitle>Sign In</CardTitle>
                  <Login/>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        <Footer/>
        </div>
    } else {
      content = <div className="main-container">
      <Header cartItems={this.state.cartItems}/>
        <Row className="mr-0 ml-0">
          <Col md="3">
            <Categories searchCat={this.searchCat}/>
          </Col>
          <Col md="9">
            <Products addProductQty={this.addToCart} searchTerm={this.searchTerm} searchFor={this.state.searchTerm} searchCatFor={this.state.searchCat}/>
          </Col>
        </Row>
        <Footer/>
        </div>
    }
    return (
      <div>
        {content}
      </div>
    );
  }
}

export default Main;