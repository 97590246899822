import React, { Component } from 'react';
import { Card, CardBody, CardTitle, CardSubtitle, CardText, Row, Col } from 'reactstrap';
import Search from './search';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons'
import PaginationComponent from "react-reactstrap-pagination";

export default class Products extends Component {
    constructor(props) {
    super(props);

    this.state = {
        products: [],
        searchTerm: "",
        activePage: 1,
        itemsPerPage: 30,
        totalItems: 0
        };
        this.searchTerm = this.searchTerm.bind(this);
        this.handlePageChange = this.handlePageChange.bind(this);
    }

    handlePageChange(pageNumber) {
        this.setState({activePage: pageNumber});
      }
    componentWillMount = () => {
        let that=this;
        const axios = require('axios');
        axios.get('http://freshanddirect.com.au/api/products/getShopProducts')
        .then(function (response) {
            that.setState({ 
                products: response.data,
            })
        })
      }
    searchTerm = async (index) => {
        this.props.searchTerm(index);
        }
    render() {
        const { activePage, itemsPerPage } = this.state;
        // Logic for displaying todos
        const indexOfLastTodo = activePage * itemsPerPage;
        const indexOfFirstTodo = indexOfLastTodo - itemsPerPage;
        let filteredProducts = this.state.products;
        let totalitems, totalpages;
        let maxpages = 5;
        if(this.props.searchCatFor){
        filteredProducts = filteredProducts.filter(product => product.category_id === this.props.searchCatFor);
        }
        if(this.props.searchFor){
        filteredProducts = this.state.products.filter(
            (product) => {
                return product.name.toLowerCase().indexOf(this.props.searchFor.toLowerCase()) !== -1;
            }
         );
        }

        totalitems = filteredProducts.length;
        totalpages = Math.ceil(totalitems/this.state.itemsPerPage)
        if(totalpages <= 5){
            maxpages = totalpages
        }
        filteredProducts = filteredProducts.slice(indexOfFirstTodo, indexOfLastTodo);
        
        if(this.state.products.length === 0){
            return (
                <div>
                    Loading Products....
                </div>
            )
        }
        if(filteredProducts.length === 0){
            return (
                <div>
                    <Row>
                        <Col md={{size: 8, offset: 2}}>
                            <Search searchTerm={this.searchTerm}/>
                        </Col>
                    </Row>
                    No products found.
                </div>
            )
        }
        return (
            <div>
                <Row>
                    <Col md={{size: 8, offset: 2}}>
                        <Search searchTerm={this.searchTerm}/>
                    </Col>
                </Row>
                <Row>
                    <div className="mx-auto">
                <PaginationComponent
          totalItems={totalitems}
          pageSize={this.state.itemsPerPage}
          onSelect={this.handlePageChange}
          maxPaginationNumbers={maxpages}
        />
        </div>
                </Row>
            <Row>
                {filteredProducts.map((product) =>
                <Col md="4" key={product.model.toString() + "_card"}>
                <Card className="no-gutters">
                    <CardBody>
                        <div className="product-image text-center">
                            <img src={"http://freshanddirect.com.au/image/"+product.image} height="150px" alt={product.name} />
                        </div>
                    <CardTitle>{product.name}</CardTitle>
                    <CardSubtitle>${product.price}</CardSubtitle>
                    <CardText className="mt-2">
                        <strong>QOH:</strong> {product.quantity}<br />
                        <strong>Carton Qty:</strong> {product.carton_qty}<br />
                        <strong>Layer Qty:</strong> {product.layer_qty}<br />
                        <strong>Pallet Qty:</strong> {product.pallet_qty}
                    </CardText>
                    <div className="input-group mb-3">
                        <div className="input-group-prepend">
                            <button className="btn btn-danger" onClick={this.props.subProductQty}><FontAwesomeIcon icon={faMinus} /></button>
                        </div>
                        <input type="number" id="qty_input" key={product.model.toString()} className="form-control form-control-sm" defaultValue="0" min="0" />
                        <div className="input-group-prepend">
                            <button className="btn btn-success" onClick={(event) => { this.props.addProductQty(); }}><FontAwesomeIcon icon={faPlus} /></button>
                        </div>
                    </div>
                    {/* <Button>Add to Cart</Button> */}
                    </CardBody>
                </Card>
                </Col>
                
                )}
                </Row>
                <Row>
                <div className="mx-auto mt-3">
                <PaginationComponent
          totalItems={totalitems}
          pageSize={this.state.itemsPerPage}
          onSelect={this.handlePageChange}
          maxPaginationNumbers={maxpages}
        />
        </div>
            </Row>
            </div>
        );
    }
}