import React, { Component } from 'react';
import Header from './header'
import {
    Container, Row, Col
} from 'reactstrap'
import ContactDetails from './contactDetails'
import Footer from './footer'

class Contact extends Component {
    render() {
        return (
            <div className="main-container">
                <Header/>
                <Container>
                    <Row>
                        <Col md="5">
                            <ContactDetails title="General Enquires" phone="(03) 5428 2549" email="warehouse@freshanddirect.com.au"/>
                            <ContactDetails title="Operations Manager" name="Jarryd Challis" phone="0407 452 272" email="warehouse@freshanddirect.com.au"/>
                            <ContactDetails title="Liquor Operations" name="Jason Wilson" phone="0418 513 117" email="jason@freshanddirect.com.au"/>
                            <ContactDetails title="IT Enquires" name="Jason Hamilton" phone="0422 413 834" email="jason@retailbs.com.au"/>
                        </Col>
                        <Col md="7">
                            Test
                        </Col>
                    </Row>
                </Container>
                <Footer/>
            </div>
        );
    }
}

export default Contact;