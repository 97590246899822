import React, { Component } from 'react';
import { Card, CardTitle, CardText } from 'reactstrap'

class ContactDetails extends Component {
    render() {
        let name;
        let phone;
        let email;
        if(this.props.name){
            name = <span><strong>Contact Name: </strong> {this.props.name}<br /></span>
        }
        if(this.props.phone){
            phone = <span><strong>Phone Number: </strong> {this.props.phone}<br /></span>
        }
        if(this.props.email){
            email = <span><strong>Email Address: </strong> <a href={"mailto:"+this.props.email}>{this.props.email}</a><br /></span>
        }
        return (
                <Card body className="mb-3">
                    <CardTitle><span className="text-uppercase"><strong>{this.props.title}</strong></span></CardTitle>
                    <CardText>{name}{phone}{email}</CardText>
                </Card>
                
        );
    }
}

export default ContactDetails;