import React, {Component} from 'react';
import { ListGroup, ListGroupItem } from 'reactstrap';


export default class Categories extends Component {
    constructor(props) {
    super(props);

    this.state = {
        categories: [],
        };
        
    }
    componentWillMount = () => {
        let that=this;
        const axios = require('axios');
        axios.get('http://freshanddirect.com.au/api/categories/getCategories')
        .then(function (response) {
            that.setState({ 
                categories: response
            })
        })
      }
      searchTerm = async (e) => {
        this.props.searchCat(e.target.id);
        }
    render() {
        if(this.state.categories.length === 0){
            return (
                <div>
                    Loading Categories....
                </div>
            ) //return false or a <Loader/> when you don't have anything in your message[]
        }
        return (
            <ListGroup className="text-uppercase mb-3 cat-list">
                <ListGroupItem key="empty" id='' onClick = {this.searchTerm}>All Products</ListGroupItem>
                {this.state.categories.data.map((category) => <ListGroupItem key={category.category_id} dangerouslySetInnerHTML={{__html: category.name}} id={category.category_id} onClick = {this.searchTerm}></ListGroupItem>)}
            </ListGroup>
        );
    }
}