import React, { Component } from 'react';
// import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'

class Search extends Component {
    constructor(props) {
        super(props);
        this.State = {
            search: ''
        }
        this.handleClick = this.handleClick.bind(this);
    }
    handleClick = (event) => {
        this.setState({
            search: event.target.value
        }, function() { this.props.searchTerm(this.state.search);});
    }

    render() {
        return (
            <div className="d-flex justify-content-center mb-3">
                <div className="searchbar">
                    <input className="search_input" type="text" name="" placeholder="Search..." onKeyUp={this.handleClick}/>
                    <a href="/test" className="search_icon"><FontAwesomeIcon icon={faSearch} /></a>
                </div>
            </div>
        );
    }
}

// Search.propTypes = {

// };

export default Search;