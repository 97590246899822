import { Component } from 'react';

class NotFound extends Component {
  render() {
    return (
      "404: NOT FOUND!"
    );
  }
}

export default NotFound;