import React from 'react';
//import PropTypes from 'prop-types';
import Routes from './routes';

class App extends React.Component {
    constructor(props) {
        super(props);
        this.toggle = this.toggle.bind(this);
        this.state = {
            isOpen: false,
            loading: 1,
        };
    }
    
    toggle() {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }
    componentDidMount(){
        this.setState({ loading: 1 });
    }
    render() {
        return (
            <div>
                {/* {
                    this.state.loading === 0 && (
                        <div className="loading">Loading&#8230;</div>
                    )
                }
                {
                    this.state.loading === 1 && ( */}
                        <Routes name={this.state.appName}/>
                    {/* )
                } */}
                
            </div>
        );
    }
}

App.propTypes = {};

export default App;
