export function PostData(type, userData){
    const axios = require('axios');
    let BaseUrl = 'https://retailbs.com.au/dev/rbs-shop/api/public/';

    let data = JSON.stringify(userData);

    return axios(BaseUrl+type, {
        method: 'POST',
        mode: 'no-cors',
        data: data,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        withCredentials: true,
        credentials: 'same-origin',
      }).then(response => response.data)

}