import React, { Component } from 'react';

class Footer extends Component {
    render() {
        return (
            <footer className="bg-success text-light text-center">
                &copy; 2019 Fresh &amp; Direct Wholesalers Pty Ltd
            </footer>
        );
    }
}

export default Footer;