import React, { Component } from 'react';
import Header from './header';

class logout extends Component {
    componentWillMount(e){
        localStorage.clear();
        sessionStorage.clear();
    }
    render() {
        return (
            <div>
                <Header/>
                Logout Successful!
            </div>
        );
    }
}

export default logout;