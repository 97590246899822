import React from 'react';
import {BrowserRouter, Route, Switch} from 'react-router-dom';

import Main from './main';
import Logout from './logout';
import Contact from './contact';
//import Signup from '././components/Signup/Signup';
import NotFound from './404';


const Routes = () => (
<BrowserRouter >
<Switch>
<Route exact path="/" component={Main}/>
<Route path="/contact" component={Contact}/>
<Route path="/logout" component={Logout}/>
{/* <Route path="/Signup" component={Signup}/> */}
<Route path="*" component={NotFound}/>
</Switch>
</BrowserRouter>
);
export default Routes;